import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import { Link } from '@models/link';

import './styles.scss';

const { none } = require('@config/styles');

type BreadcrumbProps = {
  crumbs: Link[];
  style?: string;
};

Breadcrumb.defaultProps = {
  style: ''
};

export default function Breadcrumb({ crumbs, style }: BreadcrumbProps): React.ReactElement {
  if (style === none) {
    return <></>;
  }

  const { t } = useTranslation();

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb bg-white p-0 m-0 mt-4">
          {
            crumbs.map((crumb: Link, index: number) => (
              <li
                key={crumb.link}
                className={`breadcrumb-item ${((crumbs.length - 1) === index) ? 'active' : ''} `}
              >
                <GatsbyLink
                  className="breadcrumb-link"
                  to={t(crumb?.link || '')}
                >
                  {t(crumb?.label || '')}
                </GatsbyLink>
              </li>
            ))
          }
        </ol>
      </nav>
    </div>
  );
}
