exports.default = [
  { label: 'Home', link: 'Path home page' },
];

exports.blog = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Blog', link: 'Path Blog page' }
];

exports.industries = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Industries', link: 'Path industries page' },
];

exports.learning = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Learning', link: 'Path learning page' },
];

exports.solution = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Solutions', link: 'Path solution page' },
];

exports.partnership = [
  { label: 'Home', link: 'Path home page' },
  { label: 'partnership', link: 'Path partnership page' },
];

exports.successStory = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Success story', link: 'Path Success story page' },
];

exports.technologies = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Technologies', link: 'Path technologies page' },
];

exports.whitepaper = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Whitepaper', link: 'Path whitepaper page' },
];

exports.event = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Event', link: 'Path event page' },
];

exports.services = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Services', link: 'Path services page' },
];

exports.careers = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Careers', link: 'Path careers page' },
];

exports.areas = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Areas', link: 'Path areas page' },
];

exports.clientQualyfingForm = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Client Qualifying Form', link: 'Path Client Qualyfing Form' },
];

exports.customerSuccessForm = [
  { label: 'Customer Success Form', link: 'Path Customer Success Form' },
];

exports.job = [
  { label: 'Home', link: 'Path home page' },
  { label: 'Jobs', link: 'Path jobs page' },
];
