import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { CountryContext } from '@core/providers/country/country-provider';

import translateContent from '@core/helpers/translation';
import ImageProvider from '@core/providers/image/image-provider';
import DomPurify from '@core/helpers/dom-purify';
import sortItemsByCountry from '@core/helpers/sort-items-by-country';

import Hero from '@shared/hero/hero';
import Form from '@shared/webform/form';
import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Webform } from '@models/webform';
import { Graphql } from '@models/graphql';
import { Office } from '@models/office';
import { ContactAddress } from '@shared/models/contact-address';

import allNodeContactQuery from './default-query';

import './styles.scss';

const { default: crumbs } = require('@config/breadcrumbs');

export default function ContactModule() {
  const { t, i18n } = useTranslation();
  const data = allNodeContactQuery();
  const country = useContext(CountryContext);

  const offices = translateContent(
    data?.offices?.edges ?? [],
    i18n.language
  ).map((office: Graphql) => office.node) as Office[];

  const clients = sortItemsByCountry(
    country,
    (data?.clients?.edges ?? []).map(
      (client: Graphql) => client.node as Graphql
    )
  );

  // TODO Refactor this should support multi-language without hardcode the formEs
  const form = (
    i18n.language === process.env.GATSBY_DEFAULT_LANG
      ? data?.form
      : data?.formEs
  ) as Webform;

  const heroData = {
    title: `${t('contact.bodyTitle')}`,
    subtitle: `${t('contact.hero.subtitle')}`,
    margin: 'mb-5',
    relationships: {
      image: {
        name: 'hero-contact.jpg'
      }
    }
  } as Graphql;

  const boxContact = {
    title: `${t('Write Us')}`,
    contactList: [
      {
        title: `${t('contact.support')}`,
        address: 'support@rootstack.com',
        id: 1
      },
      {
        title: `${t('Human Resources')}`,
        address: 'hr@rootstack.com',
        id: 2
      },
      {
        title: `${t('contact.accounting')}`,
        address: 'accounting@rootstack.com',
        id: 3
      },
      {
        title: `${t('contact.sales')}`,
        address: 'sales@rootstack.com',
        id: 4
      },
      {
        title: `${t('contact.publicRelations')}`,
        address: 'marketing@rootstack.com',
        id: 5
      }
    ] as ContactAddress[]
  };

  return (
    <>
      <Hero node={heroData} />
      <Breadcrumb
        crumbs={crumbs.concat({ label: heroData.title, link: '#' })}
      />
      <Container className="mt-4 mb-4">
        <Row>
          <Col sm={12} md={12} lg={6} className="p-2 p-lg-4">
            <div className="background-form">
              <Form
                title={t('Find out more about our services')}
                description=""
                webform={form}
                textColor="text-white"
              />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="p-2 p-lg-4">
            <div className="banner-title-gray card-link">
              <h2 className="text-marine text-xl text-bold mt-4 mt-lg-0">{`${t(
                'Some brands that trust us'
              )}`}</h2>
            </div>
            <div className="client-card-container">
              {clients.slice(0, 8).map((client) => (
                <div key={client?.id} className="client-card-body">
                  <ImageProvider
                    className="client-card-img"
                    fileName={client?.relationships?.image?.name}
                    alt={client?.relationships?.image?.attributes?.alt}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <div className="banner-title-gray card-link">
              <h2 className="text-marine text-3xl text-bold mt-4 mt-lg-0">{`${t(
                'Offices'
              )}`}</h2>
            </div>
          </Col>
          {offices.map((office: Office) => (
            <Col sm={12} md={4} key={office.id} className="mt-3">
              <div className="office-card card border-0 shadow-sm">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <ImageProvider
                      className="mr-2 mb-2"
                      fileName="offices-place.svg"
                      alt="place"
                      height="24"
                      width="16"
                      fluid={false}
                    />
                    <h5 className="text-marine text-lg text-bold">
                      {office.title}
                    </h5>
                  </div>
                  {office.body?.processed && (
                    <div className="text-marine">
                      <DomPurify text={office.body?.processed} />
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <ImageProvider
                      className="mr-2"
                      fileName="offices-phone.svg"
                      alt="place"
                      height="24"
                      width="16"
                      fluid={false}
                    />
                    <a href={`tel:${office.phone}`}>
                      <span className="text-marine">
                        {office.phone}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col sm={12} md={12} className="py-2 py-lg-4">
            <div className="boxes-container  boxes--activities my-4">
              <h2 className="boxes-title text-3xl text-bold text-left text-dark-indigo">
                {boxContact.title}
              </h2>

              <div className="boxes-content activities boxes-content-contact">
                {boxContact.contactList.map((box: ContactAddress) => (
                  <div className="activity-card-box" key={box.id}>
                    <p className="paragraph-box font-weight-bold m-0 text-xl">
                      {box.title}
                    </p>
                    <a
                      href={`mailto:${box.address}`}
                      className="margin-address"
                    >
                      <p className="text-emperor-grey">{box.address}</p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
